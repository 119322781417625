@import "variables";

@media (min-width: $bp-medium+1) and (max-width: $bp-large) {
  body {
    padding: 63px 0 0 0;
    font-size: 9px;
  }
  body.checkout {
    padding-top: 119px;
  }
  .navbar .container {
    width: 100%;
  }
  .navbar .right .fav {
    top: -7px;
    position: relative;
    margin-right: 15px;
  }
  .navbar .right .bag .count {
    top: 10px;
    right: 2px;
  }
  hr {
    border-color: #c8b59e;
    margin: 8px 0 23px;
  }
  h1 {
    font-size: 47px;
  }
  h2 {
    font-size: 25px;
  }
  h3 {
    font-size: 23px;
  }
  h3.border {
    font-size: 31px;
    padding-left: 171px;
  }
  h3.border:after {
    width: 152px;
    height: 2px;
    top: 23px;
  }
  h3.gold {
    width: 402px;
    font-size: 24px;
    margin-bottom: 54px;
  }
  h3.gold:after {
    width: 152px;
    height: 2px;
    top: 16px;
  }
  h3.red {
    font-size: 31px;
    margin-top: 39px;
  }
  h3.purple {
    font-size: 23px;
    margin-top: 39px;
  }
  h3.purple:after {
    width: 152px;
    height: 2px;
    top: 16px;
  }
  h3.gold-left {
    width: 397px;
    font-size: 24px;
    padding: 0 155px 0 0;
    margin-bottom: 54px;
  }
  h3.gold-left:after {
    width: 152px;
    height: 2px;
    top: 16px;
    right: 0;
  }
  h3.gold-big {
    width: 493px;
    font-size: 24px;
    margin-bottom: 54px;
  }
  h3.gold-big:after {
    width: 152px;
    height: 2px;
    top: 8px;
  }
  h4 {
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  h4.margin {
    margin-left: 171px;
    margin-bottom: 23px;
    font-size: 23px;
  }
  h5 {
    font-size: 14px;
  }
  h6 {
    font-size: 9px;
  }
  p {
    line-height: 14px;
    font-size: 9px;
  }
  form:not(.page-birthday-form) label {
    line-height: 20px;
    font-size: 11px;
  }
  form:not(.page-birthday-form) label.radio-inline,
  form:not(.page-birthday-form) label.checkbox-inline {
    font-size: 10px;
  }
  form:not(.page-birthday-form) input[type="text"],
  form:not(.page-birthday-form) input[type="email"],
  form:not(.page-birthday-form) input[type="password"],
  form:not(.page-birthday-form) input[type="number"],
  form:not(.page-birthday-form) select {
    height: 31px;
    line-height: 16px;
    padding: 9px 8px 6px;
    font-size: 9px;
  }
  form:not(.page-birthday-form) textarea {
    line-height: 16px;
    padding: 9px 8px 6px;
    font-size: 9px;
  }
  form:not(.page-birthday-form) input[type="radio"] {
    width: 11px;
    height: 11px;
  }
  form:not(.page-birthday-form) input[type="radio"]:after {
    width: 12px;
    height: 12px;
  }
  form:not(.page-birthday-form) input[type="radio"]:checked:after {
    border-width: 4px;
  }
  form:not(.page-birthday-form) input[type="checkbox"] {
    width: 11px;
    height: 11px;
  }
  form:not(.page-birthday-form) input[type="checkbox"]:after {
    width: 12px;
    height: 12px;
  }
  form:not(.page-birthday-form) input[type="checkbox"]:checked:after {
    line-height: 12px;
    font-size: 8px;
  }
  form:not(.page-birthday-form) p.help-block {
    line-height: 12px;
    font-size: 9px;
  }
  form:not(.page-birthday-form) a.help-block {
    margin-top: 7px;
    line-height: 12px;
    font-size: 9px;
  }
  form:not(.page-birthday-form) .form-group {
    margin-bottom: 12px;
  }
  .bg-danger {
    padding: 9px 8px 6px;
    font-size: 11px;
  }
  .btn-link {
    line-height: 30px;
    font-size: 9px;
  }
  .btn-link.black {
    font-size: 7px;
  }
  .btn {
    line-height: 29px;
    padding: 0 16px;
    font-size: 11px;
  }

  .navbar .container {
      width: 1240px;
  }
  .central-title {
      margin: auto;
      margin-top: 31px;
      margin-bottom: 31px;
  }
  .central-title h2 span {
    width: 310px;
    line-height: 28px;
  }
  .central-title h2 span:after {
    width: 61px;
    top: 12px;
  }
  .central-title h2 span:before {
    width: 61px;
    top: 12px;
  }
  .central-title .description {
    font-size: 12px;
    padding: 0 39px;
  }

  .navbar {
    height: 58px;
  }
  .navbar.navbar-default {
    border-width: 3px;
    border-left: 0;
    border-right: 0;
  }
  .navbar:after {
    background: #ae1f6f !important;
    position: absolute !important;
    bottom: -8px !important;
  }
  .navbar .open-menu {
    width: 32px;
    height: 24px;
    padding: 5px;
    margin: 16px 0 0 7px;
  }
  .navbar .open-menu .bar {
    width: 22px;
    height: 2px;
    margin-top: 4px;
    background: #513593;
  }
  .navbar .open-menu .bar:first-child {
    margin: 0;
  }
  .navbar .open-menu.open .bar:nth-child(1) {
    width: 27px;
    margin: 6px 0 0 -1px;
  }
  .navbar .open-menu.open .bar:nth-child(2) {
    width: 27px;
    margin: -2px 0 0 -2px;
  }
  .navbar .logo {
    margin: 3px 0 3px -70px;
    width: auto;
    height: 50px;
    left: 50%;
  }
  .navbar .logo img {
    width: auto;
    height: 49px;
  }
  .navbar.checkout .logo,
  .navbar.single .logo {
    margin-left: -44px;
  }
  .navbar .steps {
    width: 100%;
    background: #fff;
    margin: 0;
    padding: 10px 0;
    top: 63px;
    left: 0;
    position: absolute;
  }
  .navbar .steps ul {
    width: 310px;
    margin: auto;
  }
  .navbar .steps ul li {
    width: 85px;
    margin: 0 9px;
  }
  .navbar .steps ul li:after {
    width: 62px;
    top: 7px;
  }
  .navbar .steps ul li .number {
    width: 16px;
    height: 16px;
    line-height: 16px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    margin: 0 auto 4px;
    font-size: 9px;
  }
  .navbar .steps ul li .number:after {
    width: 4px;
    height: 4px;
  }
  .navbar .steps ul li .text {
    font-size: 9px;
  }
  .navbar .right {
    padding-top: 17px;
    margin: 0 10px 0 0;
  }
  .navbar .right .bag .icon {
    width: 18px;
    height: 22px;
    background-size: cover;
  }
  .navbar .right .bag .count {
    width: 22px;
    height: 21px;
    line-height: 22px;
    margin: 3px 0 0 9px;
    top: 30px;
    right: 9px;
    font-size: 12px;
  }
  #menu-mobile {
    width: 320px;
    height: calc(100% - 61px);
    top: 61px;
  }
  #menu-mobile.open {
    max-height: 800px;
  }
  #menu-mobile form {
    padding: 25px 23px;
  }
  #menu-mobile form input {
    width: calc(100% - 34px);
    -webkit-border-radius: 3px 0 0 3px;
    -moz-border-radius: 3px 0 0 3px;
    border-radius: 3px 0 0 3px;
    height: 30px;
    border: 0;
    font-size: 12px;
  }
  #menu-mobile form button {
    width: 34px;
    height: 30px;
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
    background-size: cover;
  }
  #menu-mobile > ul > li > a {
    height: 23px;
    line-height: 23px;
    padding: 12px 23px 9px;
    font-size: 17px;
  }
  #menu-mobile > ul > li.has-submenu > a:after {
    border-width: 5px 0 5px 6px;
    margin-top: -5px;
    right: 23px;
  }
  #menu-mobile > ul > li.has-submenu .submenu {
    width: 320px;
    height: calc(100% - 61px);
    top: 61px;
  }
  #menu-mobile > ul > li.has-submenu .submenu .voltar {
    padding: 15px 45px 12px;
    font-size: 12px;
  }
  #menu-mobile > ul > li.has-submenu .submenu .voltar:before {
    margin: -10px 0 0 -5px;
    border-width: 10px 10px 10px 0;
    left: 25px;
  }
  #menu-mobile > ul > li.has-submenu .submenu .voltar:after {
    margin: -10px 0 0 -5px;
    border-width: 10px 10px 10px 0;
    left: 26px;
  }
  #menu-mobile > ul > li.has-submenu .submenu .link {
    height: 23px;
    line-height: 23px;
    padding: 12px 23px 9px;
    font-size: 17px;
  }
  #menu-mobile > ul > li.has-submenu .submenu .link:after {
    border-width: 6px 5px 0 5px;
    margin-top: -5px;
    right: 23px;
  }
  #menu-mobile > ul > li.has-submenu .submenu p {
    padding: 25px 23px 15px;
    font-size: 17px;
  }
  #menu-mobile > ul > li.has-submenu .submenu ul li a {
    height: 40px;
    line-height: 20px;
    padding: 12px 40px 8px;
    font-size: 12px;
  }
  #menu-mobile > ul > li.has-submenu .submenu ul li a:after {
    width: 20px;
    height: 2px;
    left: 12px;
  }
  #menu-mobile > ul > li.institucional ul {
    padding: 10px 0;
  }
  #menu-mobile > ul > li.institucional ul li a {
    height: 40px;
    line-height: 20px;
    padding: 12px 40px 8px;
    font-size: 12px;
  }
  #menu-mobile > ul > li.institucional ul li a:after {
    width: 20px;
    height: 2px;
    left: 12px;
  }
  footer.container {
    width: 100%;
  }
  #footer {
    padding: 23px 0;
  }
  #footer .menu-footer li {
    margin: 12px 0;
  }
  #footer .menu-footer li:after {
    width: 8px;
    bottom: 2px;
  }
  #footer .menu-footer li a {
    font-size: 9px;
    line-height: 9px;
    padding: 0 0 0 12px;
  }
  #footer .formas-pagamento p {
    font-size: 9px;
  }
  #footer .formas-pagamento ul li {
    width: 43px;
    height: 20px;
    margin: 0px 5px;
    background-size: 217px auto;
  }
  #footer .formas-pagamento ul li:first-child {
    margin: 0 5px 0 0;
  }
  #footer .formas-pagamento ul li.visa {
    background-position: -43px 0;
  }
  #footer .formas-pagamento ul li.american-express {
    background-position: -87px 0;
  }
  #footer .formas-pagamento ul li.boleto {
    background-position: -130px 0;
  }
  #footer .formas-pagamento ul li.onebuy {
    background-position: -174px 0;
  }
  #footer .entrega ul li {
    margin: 12px 0;
  }
  #footer .entrega ul li h6 {
    margin: auto;
    margin-bottom: 3px;
  }
  #footer .entrega ul li .img {
    width: 24px;
    margin: auto;
    margin-right: 8px;
  }
  #footer .entrega ul li .img img {
    width: 100%;
  }
  #footer .entrega ul li p {
    margin: 0;
  }
  #footer .entrega ul li div {
    width: 172px;
  }
  #footer .entrega ul li div .text {
    width: 162px;
    font-size: 9px;
    line-height: 11px;
  }
  #footer .novidades .form-footer {
    padding-bottom: 64px;
  }
  #footer .novidades .form-footer button {
    margin: 14px 0 0 0;
    padding: 5px 4px 4px 4px;
    font-size: 9px;
    line-height: 11px;
  }
  #footer .novidades .redes-sociais {
    margin-top: 23px;
  }
  #footer .novidades .redes-sociais ul {
    margin: 19px 0 0 0;
  }
  #footer .novidades .redes-sociais ul li {
    width: 28px;
    height: 17px;
    margin: 0 7px;
  }
  #footer .novidades .redes-sociais ul li:first-child {
    margin: 0 7px 0 0;
  }
  #footer .novidades .redes-sociais ul li a {
    width: 28px;
    height: 17px;
    background-size: 140px auto;
  }
  #footer .novidades .redes-sociais ul li a.twitter {
    background-position: -28px 0;
  }
  #footer .novidades .redes-sociais ul li a.instagram {
    background-position: -56px 0;
  }
  #footer .novidades .redes-sociais ul li a.youtube {
    background-position: -84px 0;
  }
  #footer .novidades .redes-sociais ul li a.tripadvisor {
    background-position: -112px 0;
  }
  .rodape {
    margin: 19px 0;
  }
  .rodape p {
    font-size: 9px;
  }
}